var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "md-layout" }, [
      _c(
        "div",
        { staticClass: "md-layout-item" },
        [
          _c(
            "md-card",
            [
              _c("md-card-content", [
                _c("div", { attrs: { id: "typography" } }, [
                  _c("div", { staticClass: "title" }, [
                    _c("h2", [_vm._v("Typography")]),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "tim-typo" }, [
                      _c("h1", [
                        _c("span", { staticClass: "tim-note" }, [
                          _vm._v("Header 1"),
                        ]),
                        _vm._v("The Life of Material Dashboard "),
                      ]),
                    ]),
                    _c("div", { staticClass: "tim-typo" }, [
                      _c("h2", [
                        _c("span", { staticClass: "tim-note" }, [
                          _vm._v("Header 2"),
                        ]),
                        _vm._v("The life of Material Dashboard "),
                      ]),
                    ]),
                    _c("div", { staticClass: "tim-typo" }, [
                      _c("h3", [
                        _c("span", { staticClass: "tim-note" }, [
                          _vm._v("Header 3"),
                        ]),
                        _vm._v("The life of Material Dashboard "),
                      ]),
                    ]),
                    _c("div", { staticClass: "tim-typo" }, [
                      _c("h4", [
                        _c("span", { staticClass: "tim-note" }, [
                          _vm._v("Header 4"),
                        ]),
                        _vm._v("The life of Material Dashboard "),
                      ]),
                    ]),
                    _c("div", { staticClass: "tim-typo" }, [
                      _c("h5", [
                        _c("span", { staticClass: "tim-note" }, [
                          _vm._v("Header 5"),
                        ]),
                        _vm._v("The life of Material Dashboard "),
                      ]),
                    ]),
                    _c("div", { staticClass: "tim-typo" }, [
                      _c("h6", [
                        _c("span", { staticClass: "tim-note" }, [
                          _vm._v("Header 6"),
                        ]),
                        _vm._v("The life of Material Dashboard "),
                      ]),
                    ]),
                    _c("div", { staticClass: "tim-typo" }, [
                      _c("p", [
                        _c("span", { staticClass: "tim-note" }, [
                          _vm._v("Paragraph"),
                        ]),
                        _vm._v(
                          " I will be the leader of a company that ends up being worth billions of dollars, because I got the answers. I understand culture. I am the nucleus. I think that’s a responsibility that I have, to push possibilities, to show people, this is the level that things could be at."
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "tim-typo" }, [
                      _c("span", { staticClass: "tim-note" }, [
                        _vm._v("Quote"),
                      ]),
                      _c("blockquote", [
                        _c("p", [
                          _vm._v(
                            " I will be the leader of a company that ends up being worth billions of dollars, because I got the answers. I understand culture. I am the nucleus. I think that’s a responsibility that I have, to push possibilities, to show people, this is the level that things could be at. "
                          ),
                        ]),
                        _c("small", [_vm._v(" Kanye West, Musician ")]),
                      ]),
                    ]),
                    _c("div", { staticClass: "tim-typo" }, [
                      _c("span", { staticClass: "tim-note" }, [
                        _vm._v("Muted Text"),
                      ]),
                      _c("p", { staticClass: "text-muted" }, [
                        _vm._v(
                          " I will be the leader of a company that ends up being worth billions of dollars, because I got the answers... "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "tim-typo" }, [
                      _c("span", { staticClass: "tim-note" }, [
                        _vm._v("Primary Text"),
                      ]),
                      _c("p", { staticClass: "text-primary" }, [
                        _vm._v(
                          " I will be the leader of a company that ends up being worth billions of dollars, because I got the answers... "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "tim-typo" }, [
                      _c("span", { staticClass: "tim-note" }, [
                        _vm._v("Info Text"),
                      ]),
                      _c("p", { staticClass: "text-info" }, [
                        _vm._v(
                          " I will be the leader of a company that ends up being worth billions of dollars, because I got the answers... "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "tim-typo" }, [
                      _c("span", { staticClass: "tim-note" }, [
                        _vm._v("Success Text"),
                      ]),
                      _c("p", { staticClass: "text-success" }, [
                        _vm._v(
                          " I will be the leader of a company that ends up being worth billions of dollars, because I got the answers... "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "tim-typo" }, [
                      _c("span", { staticClass: "tim-note" }, [
                        _vm._v("Warning Text"),
                      ]),
                      _c("p", { staticClass: "text-warning" }, [
                        _vm._v(
                          " I will be the leader of a company that ends up being worth billions of dollars, because I got the answers... "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "tim-typo" }, [
                      _c("span", { staticClass: "tim-note" }, [
                        _vm._v("Danger Text"),
                      ]),
                      _c("p", { staticClass: "text-danger" }, [
                        _vm._v(
                          " I will be the leader of a company that ends up being worth billions of dollars, because I got the answers... "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "tim-typo" }, [
                      _c("h2", [
                        _c("span", { staticClass: "tim-note" }, [
                          _vm._v("Small Tag"),
                        ]),
                        _vm._v(" Header with small subtitle "),
                        _c("br"),
                        _c("small", [
                          _vm._v('Use "small" tag for the headers'),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header text-center" }, [
      _c("h3", { staticClass: "title" }, [
        _vm._v("Material Dashboard Heading"),
      ]),
      _c("p", { staticClass: "category" }, [
        _vm._v("Created using Roboto Font Family "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }